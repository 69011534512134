#Users {
  // background: url("../../assets/images/homeMenu/Fond.jpg") no-repeat;
  margin: 0;
  padding-top: 7vh;
  width: 100vw;
  // background-attachment: fixed;
  background-size:  cover;
  background-position: center;
  height: 100vh;
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  overflow: hidden;
  
}
.back-gestions-utilisateurs :hover{
  cursor: pointer;
}
.users-content {
  // background-color: red;
  position: relative;
  top: 5vh;
  width: 80vw;
  margin: 0 auto;
}
.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  // position: unset!important;
  margin: auto;
}