
.connexion {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent  inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  background: url("../../assets/images/Fond-Login.jpg");
  background-size: 100% 100%;
  height: 100vh;
  width: 100vw;
  display: flex;
  font-size: var(--font-size-m);

  .formContainer {
    margin: auto;
    width: 15.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #gif {
      height: 5vw;
      width: auto;
      margin-right: auto;
      margin-left: auto;
    }

    .ant-form-item-explain-error {
      margin-left: 0.5vw;
      font-size: var(--font-size-m);
      min-height: 0;
    }

    .ant-form {
      margin-top: 4.4vw;

      .ant-row {
        margin-bottom: 0.8vw;
      }

      .ant-form-item-control {
        width: 100%;
        .ant-form-item-control-input, .ant-input-affix-wrapper, .ant-form-item-control-input-content {
          height: 2.3vw;
        }

        .ant-input-affix-wrapper {
          border-radius: 10vw;
          background: rgba($color: #ffffff, $alpha: 0.2);
          color: white;
          border: none;

          input {
            background: transparent;
            font-size: var(--font-size-m);
            color: white;

            &::placeholder {
              color: white;
            }
          }

          .ant-input-prefix {
            width: 2vw;
            justify-content: center;

            img {
              height: 1.3vw;
              width: auto;
            }

            .logo_mdp_connexion {
              height: 1.36vw;
            }
          }
        }

        .ant-form-item-control-input-content {
          .ant-btn {
            height: 100% !important;
            width: 100%;
            border-radius: 10vw !important;
            margin-top: 0.9vw !important;
            background: rgba($color: #000000, $alpha: 0.35) !important;
            border: none !important;
            color: white !important;
            font-weight: bold;
            font-size: var(--font-size-l) !important;
          }
        }
      }

    }


    #toggler {
      margin-top: 0.6vw;
      color: white;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }
  }

}

.reset_password_modal {
  display: flex;

  .ant-modal {
    margin: auto !important;
    width: 28vw !important;
  }

  .ant-modal-footer {
    border-top: none !important;
    display: flex;
    justify-content: center;
    padding: var(--spacing-15);

    button {
      margin: 0 var(--spacing-10) !important;
      border-radius: 10vw !important;
      padding: 0.5vw 1.5vw !important;

      &:first-child {
        background: var(--color-degrade-violet-bleu) !important;
        color: white !important;
        border: none !important;
      }

      &:last-child {
        color: var(--color-gris-fonce) !important;
        background-color: white !important;
        border: none !important;
      }
    }
  }

  .ant-modal-body {
    border: none;
  }

  .ant-modal-content {
    height: auto;
    width: 100%;
    border-radius: 1vw;
    overflow: hidden;
    margin: auto;
    padding: var(--spacing-5) 0;
    //width: 50%;
    .anticon-close {
      display: none;
    }

    .form_reset_password {
      display: flex;
      flex-direction: column;
      text-align: center;

      h1 {
        //font-weight: 600;
        font-size: var(--font-size-xl);
        margin-bottom: var(--spacing-15);
        font-family: "Poppins SemiBold", sans-serif;
      }

      label {
        margin-bottom: var(--spacing-10);
        font-size: var(--font-size-m);
      }

      input:focus {
        border: 3px solid #417bf6;
      }

      input {
        outline: none;
        padding-left: 3%;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10vw;
        border: 1px solid var(--color-gris-clair);
        height: 1.6vw;
        font-size: var(--font-size-m);
      }
    }
  }
}