@import "./sass/variable";
@import "./sass/mixin";

@font-face {
  font-family: "Poppins Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}


@font-face {
  font-family: "Poppins Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins Light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}


body {
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  background: #fcfcfc !important;
  //letter-spacing: 0.05vw;
  .equipments-list {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    min-width: 150px;

    a {
      text-decoration: none;
      color: black;
    }

    .ctn-input-equipment-list {
      border-bottom: 10px;
      display: flex;

      input {
        margin: 7px auto;
        border: 1px solid gray;
        width: 90%;
        border-radius: 4px;
      }

      .ctn-equipments-map {
        max-height: 200px;
        overflow-y: scroll;
      }
    }

    .ctn-equipment-map {
      display: flex;
      flex-wrap: nowrap;
      padding: 12px 15px;

      &:hover {
        background-color: whitesmoke;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    scroll-margin: 50px 0 0 50px;
  }

  ::-webkit-scrollbar-thumb {
    border: 3px white solid;
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-radius: 25px;
    background-clip: padding-box;

    &:hover {
      @include gradient(to top, #6275c7, #5763be);
    }
  }

  .bg-sayse {
    background: linear-gradient(#4283c6, #233e92);
  }

  .bg-pink {
    background-color: #db5089;
  }

  .bg-yellow {
    background-color: #f5c87f;
  }

  @for $i from 0 through 100 {
    .w-#{$i} {
      width: 1% * $i !important;
      transition: all 400ms;
    }
  }

  .byos-input {
    padding: 5px 20px !important;
    border: 1px solid rgba($color: #000000, $alpha: 0.15) !important;

    box-shadow: 0px 5px 12px rgba($color: #000000, $alpha: 0.01);

    &:focus,
    &:active {
      border: 1px solid rgba($color: #000000, $alpha: 0.15) !important;
      transition: 0.3s;
    }

    &.input-labo {
      padding: 0px !important;
    }

    .ant-select-arrow {
      color: $blue-light !important;
    }

    &.required {
      .ant-select-selector {
        border: 1px solid red !important;
        color: red !important;
      }

      &.ant-input {
        border: 1px solid red !important;
        color: red !important;
      }

      .ant-select-arrow {
        color: red !important;
      }
    }
  }

  .byos-input.ant-select-single {
    border: none !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {

  }

  .contain-select.dark {
    .ant-select {
      color: white;

      & .ant-select-selector {
        background-color: #1f1f1f !important;
        border: none !important;
      }

      & .ant-select-arrow {
        color: white !important;
      }
    }
  }

  .ant-select-dropdown {
    //display: block!important;
    @include glassesEffect(0.7, 15px);

    border-radius: var(--border-radius-1) !important;
    position: relative;
    padding: 0 !important;
    width: 10vw !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    //padding-top: 0;
    .ant-select-item-option-active {
      color: black !important;
      //font-weight: bold !important;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: rgba(228, 239, 253, 0.7);
      font-family: "Poppins Regular", sans-serif;
    }

    .ant-select-item {
      padding: 0.6vw 0.8vw;
      //color: var(--color-grey-muted);
      font-family: "Poppins Regular", sans-serif;
      font-size: var(--font-size-m) !important;
      color: var(--color-gris-fonce);
    }
  }

  .ant-select-dropdown.dark {
    .ant-select-item {
      color: white !important;
    }

    .ant-select-item-option-selected {
      @include gradient(to top, $blue-dark, $blue-light);
    }

    .ant-select-item-option-active {
      @include gradient(to top, $blue-dark, $blue-light);
    }
  }

  .byos-input-dark {
    padding: 5px 20px !important;
    border: 1px solid rgba($color: #000000, $alpha: 0.15) !important;
    box-shadow: 0px 5px 12px rgba($color: #000000, $alpha: 0.1);
    background: $dark-color !important;
    color: white !important;

    &:focus,
    &:active {
      border: 1px solid rgba($color: #000000, $alpha: 0.15) !important;
      box-shadow: 0px 0px 0px rgba($color: #000000, $alpha: 0.1) !important;
      width: 50% !important;
      transition: 0.3s;
    }

    &.ant-input {
      background: $dark-color !important;
      border-radius: 25px !important;

      &::placeholder {
        color: white !important;
      }
    }

    & .ant-select-selector {
      background: $dark-color !important;
      border: 1px solid $dark-color !important;
      color: white !important;
      box-shadow: none !important;
    }

    .ant-select {
      color: white !important;
    }

    .ant-select-arrow {
      color: white !important;
    }
  }

  .option-menu-dark {
    color: white !important;

    &:hover {
      background: rgba($color: $blue-dark, $alpha: 0.5);
    }
  }

  // .ant-select-single.byos-input,
  // .ant-select-single.byos-input-dark {
  //   width: 147px !important;
  // }

  .ant-select-item-option-active.option-menu-dark {
    @include gradient(to right, $blue-light, $blue-dark);
  }

  .ant-select-item-option-selected.option-menu-dark {
    @include gradient(to right, $blue-light, $blue-dark);
  }

  .dark-body .ant-drawer-body {
    background-color: #282828;
  }

  .dark-drawer {
    color: white !important;
    background: $dark-color-light !important;

    .ant-collapse-content-box {
      color: white !important;
      background: $dark-color-light !important;
    }

    .byos-input-dark {
      background: $dark-color !important;
      width: 147px;
      color: white !important;

      .ant-select-selector {
        background: $dark-color !important;
        border: 1px solid $dark-color !important;
      }
    }

    .ant-collapse-header {
      color: white !important;
    }

    #record {
      border-right: none !important;
    }

    .bloc-drag-n-drop .drag-n-drop-style-dark {
      background: $dark-color !important;

      color: white !important;

      .details-drag-n-drop {
        color: white !important;
      }
    }
  }

  .input-collapse {
    position: relative;

    .block-drawer-outline {
      position: relative;
      border: 1px solid rgba($color: #000000, $alpha: 0.15);

      padding: 35px;

      &.dark {
        border: 1px solid rgba($color: #fff, $alpha: 0.15) !important;
      }
    }

    .byos-input {
      width: 147px;
    }
  }

  .ant-collapse {
    border: none !important;

    .ant-collapse-item {
      border-bottom: none !important;
    }
  }

  .title-drawer-outline {
    font-size: 25px;
    font-weight: bold;
  }

  .radio--action {
    .ant-tag {
      font-size: var(--font-size-m) !important;
    }
  }

  .radio-action-dark {
    .ant-radio {
      .ant-radio-inner {
        color: #1f1f1f !important;
        background: #1f1f1f !important;
        border: 1px solid #1f1f1f !important;
      }
    }

    span {
      color: #fff !important;
    }
  }


  .dropdown-dark {
    & .ant-picker-panel-container {
      background: $dark-color-light !important;

      & .ant-picker-panel {
        border-bottom: 2px solid $dark-color !important;

        & .ant-picker-header {
          & button {
            color: white !important;
          }

          color: white !important;
          border-bottom: 2px solid $dark-color;
        }

        & .ant-picker-content {
          color: white !important;

          & thead tr th {
            color: white !important;
          }

          & .ant-picker-cell-in-view div {
            color: white !important;

            &:hover {
              color: $dark-color !important;
            }
          }

          & .ant-picker-cell:hover .ant-picker-cell-in-view div {
            color: $dark-color !important;
          }

          & .ant-picker-cell-inner {
            color: $dark-color !important;
          }
        }

        & .ant-picker-footer {
          border-top: 2px solid $dark-color !important;
          border-bottom: 2px solid $dark-color !important;
          color: white;
        }
      }
    }
  }

  .byos-input.search {
    & svg path {
      fill: rgba($color: #000000, $alpha: 0.25) !important;
    }
  }

  .byos-input-dark.search,
  .byos-input-dark.calendar {
    box-shadow: 0 5px 12px rgba($color: #000000, $alpha: 0.25);

    & .ant-picker-clear {
      background: $dark-color-light !important;
    }

    & .ant-picker-input > input,
    & .ant-input {
      color: white !important;
    }

    & svg path {
      fill: white !important;
    }
  }

  .light-dark-bg {
    background: $dark-color-light !important;
  }

  .light-dark-text {
    color: $dark-color-light !important;
  }

  .dark-bg {
    background: $dark-color !important;
  }

  .dark-text {
    color: $dark-color !important;
  }

  .form-inline label {
    display: block !important;
  }

  // .ReactTable.dark-table {
  //   color: white;
  //   background-color: #282828;
  //   border-radius: 10px;
  //   input,
  //   select {
  //     background: #1f1f1f !important;
  //   }
  //   .rt-th {
  //     background: #282828;
  //     // color: white;
  //     // padding: 0;
  //     font-family: "Poppins", sans-serif;
  //     font-weight: bold;
  //   }
  // }

  .dark-slide {
    div[aria-selected="false"] {
      color: white !important;
    }
  }

  .btn {
    padding: 5px 10px !important;

    border: none !important;
  }

  .title-page {
    display: flex;

    img {
      height: var(--img-title-page-height);
      margin-top: auto;
      margin-bottom: auto;
    }

    h2 {
      font-family: "Poppins SemiBold", sans-serif;
      margin-bottom: 0;
      margin-left: 0.55vw;
      font-size: var(--font-size-xxl);
      font-weight: bold !important;
    }
  }

  .ant-btn,
  .ant-picker-ok button {
    // border-radius: 15px !important;
    // color: #397df8 !important;
    // border: 1px solid #397df8 !important;
    font-size: var(--font-size-xs) !important;
    padding: 5px 15px !important;
    height: unset !important;
    // margin: 0 !important;
    // background: none !important;
  }

  .ant-picker-ok {
    margin: 0 !important;
  }

  .ant-btn:hover,
  .ant-picker-ok button:hover {
    // background: #397df8 !important;
    // color: white !important;
  }

  .ant-btn-lg {
    height: inherit !important;
  }

  .btn-blue {
    @include gradient(to bottom, $blue-light, $blue-dark);
    color: white !important;
  }

  .btn-connexion {
    .btn-co {
      background: #54d1a7;
      color: white;
      text-align: center;
      width: 150px !important;

      &:hover {
        color: white !important;
      }
    }

    .btn-deco {
      width: 150px !important;
      background: #e6508b;
      color: white;
      text-align: center;

      &:hover {
        color: white !important;
      }
    }
  }

  .bloc-drag-n-drop {
    #record {
      height: 50px;
      padding-left: 30px;
      font-family: "Poppins", sans-serif;
      font-size: var(--font-size-m);
      font-style: italic;
      font-weight: normal;
      color: #727272;
      border-right: 60px solid #72727233;
      margin-bottom: 10px;
      background: #e2e2e26b;
      position: relative;

      .details-drag-n-drop {
        font-family: "Poppins", sans-serif;
        font-size: var(--font-size-m);
        font-style: italic;
        position: absolute;
        right: 30px;
        top: calc(50% - 10px);

        &:hover {
          color: #4283c6 !important;
        }
      }
    }

    .drag-n-drop-style {
      border: none !important;
      background-color: white !important;
      box-shadow: 0px 2.5px 2.5px 1.5px rgba(0, 0, 0, 0.24) !important;
      border-radius: 6px !important;
      height: 5vh;
      position: relative;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }

    .rc-draggable-list-draggableRow .rc-draggable-list-handles {
      display: none;
    }
  }

  .highcharts-credits {
    display: none;
  }

  .title-type-sayse {
    font-family: "Poppins SemiBold", sans-serif;
    font-size: 16px;
    margin-bottom: 6px;
    margin-top: 10px;
  }

  // React Table


  .ReactTable__no_data {
    .rt-tr-group {
      border: none !important;
    }
  }


  .loader__d {
    height: 100%;
    width: 100%;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 10vw;
      width: 10vw;
      transform: translate(-50%, -50%);
      background: url("./assets/images/404_NoData/Loader-2.gif") no-repeat !important;
      background-size: 10vw 10vw !important;
      background-position: center;
    }
  }

  .ReactTable__loading {
    .rt-noData {
      color: transparent;

      height: 90%;
      width: 100%;

      &:before {
        content: '';
        position: absolute;
        height: 10vw;
        width: 10vw;
        background: url("./assets/images/404_NoData/Loader-2.gif") no-repeat !important;
        background-size: 50% auto !important;
        left: 50%;
        transform: translateX(-50%);
      }

      &:after {
        content: "" !important;
        position: absolute;
        color: #000;
        font-size: var(--font-size-m);
        left: 50%;
        top: 45%;
        transform: translateX(-50%);
        font-family: var(--font-family-poppins);

      }
    }
  }

  .ant-switch {
    height: 0.85vw;
    width: 1.75vw;
    min-width: 0;
    .ant-switch-handle {
      top: 0.085vw;
      left: 0.1vw;
      height: 0.7vw;
      width: 0.7vw;
    }

  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 0.7vw - 0.1vw)!important;
  }

  .ReactTable {
    position: relative;
    border: none;
    .ant-switch {
      height: 0.85vw;
      width: 1.75vw;
      min-width: 0;
      .ant-switch-handle {
        top: 0.085vw;
        //left: 0.085vw;
        height: 0.7vw;
        width: 0.7vw;
      }
      .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 0.7vw - 0.085vw)!important;
      }
    }

    input[type="checkbox"] {
      -moz-appearance:none;
      -webkit-appearance:none;
      -o-appearance:none;
      outline: none;
      content: none;
      margin-left: 0.2vw;
      &:before {
        content: "\f00c";
        font-size: var(--font-size-m);
        color: transparent !important;
        background: transparent;
        display: block;
        width: 0.45vw;
        //margin: auto;
        height: 0.45vw;
        border: 1px solid var(--color-gris-fonce);
        border-radius: 0.1vw;
      }
      &:checked:before {
        background-color: var(--color-bleu);
      }
    }
    .rt-noData {
      color: white;
      height: 86.5%;
      //border: 1px solid green;
      width: 100%;
      position: absolute;
      padding: 0;
      bottom: 0;
      left: 0;
      top: 13.5%;
    transform: translate(0);
      &:before {
        content: '';
        position: absolute;
        height: 40%;
        width: 15vw;
        background: url("./assets/images/404_NoData/Notfound-boitier.svg");
        background-size: 100% 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)!important;

      }

      &:after {
        content: "No data";
        position: absolute;
        color: #000;
        font-size: var(--font-size-m);
        left: 50%;
        top: 50%;
        transform: translate(-50%, 300%);
        font-family: var(--font-family-poppins);

      }
    }

    .rt-thead {

      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      .rt-resizable-header-content {
        font-size: var(--font-size-m) !important;
        font-family: "Poppins SemiBold", sans-serif !important;
      }

      box-shadow: none !important;

      .rt-th {
        margin-top: var(--spacing-5);
        margin-bottom: var(--spacing-5);
        padding: 0 1vw!important;
        input {
          font-family: "Poppins Regular", sans-serif!important;
          border-radius: 0.4vw !important;
          width: 100%!important;
        }
      }
    }

    .rt-thead.-filters .rt-tr .rt-th:first-child,
    .rt-thead.-header .rt-tr .rt-th:first-child {
      .inputfilters {
        //width: 100% !important;
      }

      //padding-left: 25px !important;
    }

    .rt-thead .-filters .rt-tr .rt-th:last-child,
    .rt-thead.-header .rt-tr .rt-th:last-child {
      .inputfilters {
        //width: 100% !important;
      }

      //padding-right: 25px !important;
    }

    //.rt-tr-group {
    //  padding: 0 0.7vw;
    //}

    .rt-tr {
      border-right: none !important;
      border-left: none !important;
      border-top: 1px black !important;
      & .rt-th:first-child .rt-resizable-header-content {
        text-align: left !important;
      }

      & .rt-th:last-child .rt-resizable-header-content {
        text-align: right !important;
      }

      & div:first-child {
        .inputfilters {
          text-align: left !important;
        }
      }

      & div:last-child .inputfilters {
        text-align: right !important;
        padding-right: 25px;
      }

      .inputfilters {
        height: var(--height-input-text-form) !important;
        font-family: "Poppins Regular", sans-serif !important;
        border-radius: 10px !important;
        color: black;
        padding: var(--spacing-10) !important;
        font-size: var(--font-size-m) !important;
        //width: 80% !important;

        &::placeholder {
          color: #e6e6e6 !important;
        }
      }
    }

    .rt-td {
      font-size: var(--font-size-m) !important;
      font-family: "Poppins Medium", sans-serif !important;
      // display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      // height: 40px;

      &::first-letter {
        text-transform: uppercase !important;
      }
    }

    .rt-th {
      margin-bottom: var(--spacing-5);
      border-right: none !important;
      padding: 0 !important;
    }

    .rt-resizable-header-content {
      color: rgba(0, 0, 0, 0.178) !important;
    }

    .rt-tr-group .rt-tr.-even,
    .rt-tr-group .rt-tr.-odd {
      background: white !important;
    }

    .rt-tr-group .rt-tr.-even .rt-td:last-child,
    .rt-tr-group .rt-tr.-odd .rt-td:last-child {
      justify-content: flex-end ;
      padding-right: 10px;
    }

    .rt-tr-group .rt-tr.-even .rt-td:first-child,
    .rt-tr-group .rt-tr.-odd .rt-td:first-child {
      justify-content: left !important;
    }

    .rt-thead .rt-th.-sort-asc,
    .rt-thead .rt-th.-sort-desc {
      box-shadow: none !important;
    }


    .rt-tbody {
      // height: calc(var(--base-height-table-body));
      overflow-y: scroll !important;

      //.rt-tr-group, .rt-tr, .rt-td {
      //  height: 10px!important;
      //}
    }


    .rt-td {
      text-align: center ;
      border-right: none !important;
      color: black;
      padding: 0 1vw!important;
    }

    .pagination-top {
      position: absolute;
      top: calc(-1.25vw - 1vw);
      right: 0;
      font-size: var(--font-size-m);

      .-pagination {
        display: grid;
        grid-template-areas: "center previous next";
        background-color: transparent;
        border: none;
        box-shadow: none;
        grid-gap: 0.7vw;
        color: var(--color-gris-fonce);

        .-center {
          grid-area: center;
          display: flex;

          flex-direction: row-reverse;

          .-pageSizeOptions {
            position: relative;
            z-index: 1;
            margin-right: 2vw!important;
            select {
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
              padding: 0.1vw 0.2vw!important;
            }
          }

          .-pageInfo {
            position: relative;
            margin-left: -1.7vw;
            margin-right: 0;

            &:before {
              position: absolute;
              content: "";
              width: 1.6vw;
              height: 100%;
              background-color: #fcfcfc;
            }

            &:after {
              content: "sur";
              position: relative;
              left: -1.9vw;
              padding-left: 0.3vw;
              background-color: #fcfcfc;
              //width: 2vw;
            }
          }
        }

        .-previous {
          grid-area: previous;
          display: flex;

          & > .-btn {
            transform: scaleX(-1) !important;
            background: url("./assets/images/Fleche-grisfoncé.svg") no-repeat !important;
          }
        }

        .-next {
          grid-area: next;
          display: flex;

          & > .-btn {
            background: url("./assets/images/Fleche-grisfoncé.svg") no-repeat !important;
          }
        }

        .-next > .-btn,
        .-previous > .-btn {
          overflow: hidden;
          background-size: 0.3vw !important;
          background-position-x: 45% !important;
          background-position-y: center !important;
          margin-top: auto;
          margin-bottom: auto;
          width: var(--font-size-xl) !important;
          height: var(--font-size-xl) !important;
          border-radius: 100px;
          //height: var(--font-size-m) !important;
          text-indent: -9999px;
          //width: 10px;
        }

        .-next > .-btn:hover,
        .-previous > .-btn:hover {
          background-color: var(--color-gris-clair) !important;
        }


        .-previous .-btn[disabled] {
          background-color: rgba($color: #000000, $alpha: 0) !important;
        }

        select, input {
          padding: 0 !important;
          border: none;
        }

        .-pageJump {
          input {
            width: 3vw;
            padding: 0.1vw 0.2vw!important;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
          }
        }

        .-totalPages {
          margin-left: 0.8vw;
        }
      }
    }

    //.pagination-top {
    //  .-pagination {
    //    display: flex !important;
    //    align-items: center !important;
    //    position: absolute;
    //    top: -2.5vw;
    //    right: 0;
    //    box-shadow: none !important;
    //    border: none !important;
    //  }
    //
    //  .-center {
    //    position: absolute;
    //    top: 0;
    //    margin-top: 0;
    //    right: 100px;
    //    display: flex !important;
    //    align-items: center !important;
    //
    //    .-pageInfo,
    //    .select-wrap {
    //      margin: 0 10px;
    //    }
    //
    //    .-pageJump input {
    //      width: 30px !important;
    //
    //      &::-webkit-inner-spin-button {
    //        -webkit-appearance: none !important;
    //      }
    //    }
    //  }
    //
    //  .-center input {
    //    background-color: rgba(228, 228, 228, 0) !important;
    //    border: none !important;
    //    border-radius: 20px !important;
    //    width: 30px !important;
    //  }
    //
    //  .-center select {
    //    cursor: pointer;
    //    background-color: white !important;
    //    border: none !important;
    //
    //    padding: 0 !important;
    //    outline: none !important;
    //    font-size: var(--font-size-m) !important;
    //    color: rgba(119, 118, 118, 0.521);
    //    box-shadow: 5px 5px 12px rgba($color: #000000, $alpha: 0.025) !important;
    //  }
    //
    //  // .-center::after {
    //  // position: absolute;
    //  // right: 0;
    //  // content: "";
    //  // width: 0.8em;
    //  // height: 0.5em;
    //  // background-color: rgba(187, 184, 184, 0.822);
    //  // clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    //  // }
    //
    //  .-pageInfo {
    //    font-size: var(--font-size-m) !important;
    //    color: rgba(119, 118, 118, 0.521);
    //    position: absolute;
    //    top: 2px;
    //    right: 100px;
    //    padding: 0 !important;
    //  }
    //
    //  .-pageJump > input {
    //    text-align: end !important;
    //    outline: none;
    //    -webkit-appearance: none;
    //    background-color: white !important;
    //    height: var(--font-size-m);
    //    padding: 0.4vw var(--spacing-10);
    //
    //    box-shadow: 5px 5px 12px rgba($color: #000000, $alpha: 0.025) !important;
    //  }
    //
    //  .-next > .-btn {
    //    position: absolute;
    //    right: 0;
    //    top: 0;
    //    transform: scaleX(-1) !important;
    //    background: url("./assets/images/arrow.png") no-repeat !important;
    //    font-size: var(--font-size-m);
    //  }
    //
    //  .-previous > .-btn {
    //    background: url("./assets/images/arrow.png") no-repeat !important;
    //    position: absolute;
    //    right: 50px;
    //    top: 0;
    //    width: var(--font-size-xs) !important;
    //    height: var(--font-size-xs) !important;
    //  }
    //
    //  .-next > .-btn,
    //  .-previous > .-btn {
    //    overflow: hidden;
    //    background-size: 7px !important;
    //    background-position-x: 6px !important;
    //    background-position-y: 4px !important;
    //    background-color: var(--color-gris-clair) !important;
    //
    //    width: var(--font-size-xs) !important;
    //    height: var(--font-size-xs) !important;
    //    border-radius: 100px;
    //    padding: 10px;
    //    //height: var(--font-size-m) !important;
    //    text-indent: -9999px;
    //    //width: 10px;
    //  }
    //
    //  .-previous .-btn[disabled] {
    //    background-color: rgba($color: #000000, $alpha: 0) !important;
    //  }
    //}
  }
}

.ant-tooltip-inner {
  font-size: var(--font-size-m);
}

//.select-dropdown-glasses {
//  border-radius: var(--border-radius-1) !important;
//  // background: inherit !important;
//  position: relative;
//  padding: 1vw 0 !important;
//  width: 10vw !important;
//
//  &:before {
//    content: "";
//    background: inherit !important;
//    position: absolute;
//    left: 0;
//    right: 0;
//    top: 0;
//    bottom: 0;
//    box-shadow: inset 0 0 0 3000vw rgba(255, 255, 255, 0.91);
//    filter: blur(10px);
//  }
//
//  .ant-select-item-option-active {
//    color: black !important;
//    font-weight: bold !important;
//  }
//
//  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
//    background-color: rgba(228, 239, 253, 0.7);
//  }
//
//  .ant-select-item {
//    font-weight: 600;
//    padding: 0.6vw 0.8vw;
//    color: var(--color-grey-muted);
//
//    font-size: var(--font-size-m) !important;
//  }
//}

.dropdown-perso-base {
  padding: var(--spacing-15) !important;
}

.notfound-section {
  height: 100%;
  width: 100%;
  position: relative;

  .notfound-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
  }
}

.loader-container-large {
  min-height: 350px;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
  }
}

.ant-select-arrow {
  .anticon-down {
    svg {
      path {
        d: path("M247.27,0.13L123.12,148.4L0.27,0.13H247.27z") !important;
        transform: translate(0, 40%) scale(3) !important;
        stroke: #000000 !important;
        fill: #000000;
      }
    }
  }
}

.ant-modal-mask {
//@include glassesEffect(0, 15px);
  background: transparent;
}

.option-bar {
  @include actionBarTable;
}

.ant-drawer {
  .ant-drawer-close {
    display: none;
  }
}