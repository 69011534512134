@import "../../../sass/variable";
@import "../../../sass/mixin";

.ant-select-arrow {
  position: absolute!important;
  top: 47.3%!important;
  right: 3%;
}

#navbar-top .ctn-1 .ant-select .ant-select-selector{
  border: none !important;
  background-color: red;
}

#navbar-top {
  #logout--button {
    border-top: #e7e7e7 1px solid;
  }
  .notifications_icon {
    height: var(--height-input-text-form);
    width: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: red;
  z-index: 99;
  .msg-alert {
    position: relative;
    //height: var(--height-input-text-form);
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .icon-alert {
    left:50%;
    position: absolute;
    top: -25%;
    //transform: translateY(-50%);
    div {
      //position: absolute!important;
      background-color: #F21934;
      font-size: var(--font-size-xs)!important;
      padding: 0.1vw 0.4vw;
      //transform: translate(-50%, -50%);

      border-radius: 1vw;
    }
  }
  &.clps-close {
            width: calc(100vw - 5.6vw);
          }
  .ctn-1 {
    display: flex;
    justify-content: flex-end;
    width: 34.6vw;
    padding: 0 3.1vw;
    margin-top: var(--spacing-20);
    margin-bottom: var(--spacing-20);

    .ant-select-focused {
      .ant-select-selector {
        border: 3px solid #417bf6!important;

      }
    }
    .ant-select {
      height: var(--height-input-text-form);
      font-size: var(--font-size-m);
      margin-top: auto;
      margin-bottom: auto;
      position: relative;
      display: flex;
      .ant-select-arrow {
        //margin-top: auto;
        margin-bottom: auto;
        margin-right: 0.5vw;
        top: 50%!important;
        height: var(--font-size-l);
        position: absolute!important;
        margin-top: 0;
        transform: translateY(-50%);
        //top: 0!important;
        ////height: 100%;
        //
        //top: 50%!important;
        //transform: translateY(-50%);
        img {
          position: absolute;

        }
      }
      .ant-select-selector {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid var(--color-gris-clair)!important;
        border-radius: 10vw!important;
        padding: 0 0.8vw;
        input {

          font-size: var(--font-size-l);
          font-family: "Poppins Light", sans-serif;
          height: 100%!important;
        }
      }
      .ant-select-selection-item {

        margin-top: auto;
        margin-bottom: auto;

      }

      .input {
        background-color: blue;
      }
      & > * {
        height: var(--height-input-text-form);
      }

      .ant-select-selection-placeholder {
        line-height: var(--height-input-text-form);
        font-size: var(--font-size-l);
        font-family: "Poppins Light", sans-serif;
      }

      width: 79%;
    }
  }

  .ctn-icons {
    display: flex;
    justify-content: space-around;
    margin-left: var(--spacing-20);
    //margin-left: var(--spacing-15);

    a, div {
      display: flex;

      svg {
        color: var(--color-noir);
        margin-top: auto;
        margin-bottom: auto;
        font-size: var(--font-size-xxl);
      }
    }
  }

  .menu-navbar-top {
    // display: flex;
    position: absolute;
    right: 0;
    padding: 25px;
    // justify-content: flex-end;
    margin-top: auto;
    margin-bottom: auto;


    #entity-dashboard {
      .ant-select-focused {
        .ant-select-selector {
          box-shadow: none !important;
        }
      }
      .ant-select-selection-item {
        font-family: "Poppins SemiBold", sans-serif ;
      }
      margin-top: auto;
      margin-bottom: auto;
      margin-right: var(--spacing-15);

      .ant-select {
        font-size: var(--font-size-m) !important;

        .ant-select-selector {
          border: none;
          outline: none;
          background-color: transparent;

          .ant-select-selection-item {
            width: auto;

            font-size: var(--font-size-l);
          }
        }
      }
    }

    .profile-menu {
      // border-radius: 50%;
      height: 5vw;
      overflow: hidden;
      width: 5vw;
      position: relative;
      cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: rgba(246, 244, 243, 0.3) 6px solid;
        border-radius: 60px;
      }
    }


    #navbar-img-profile {
      position: relative;

      #menu-drawer-nav {
        @include glassesEffect(0.7, 15px);
        position: absolute;
        top: 0.5vw;
        right: 1vw;
        //padding: var(--spacing-10) 0;
        overflow: hidden;
        font-size: var(--font-size-m);
        text-align: center;
        border-radius: var(--border-radius-1);
        box-shadow: 0 0 25px rgba(0,0,0,0.1);
        width: 13vw;


        & a {
          transition: 0.3s;
          text-decoration: none;
          color: rgb(40, 40, 40);
          font-size: var(--font-size-m);
          font-family: "Poppins Medium", sans-serif;
          margin-top: 0.3vw;
          padding: 0.6vw 0.8vw;
          display: flex;
          line-height: 2px;
          img {
            height: var(--font-size-m);
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
          }
          &:hover {
            background: rgba($blue-light, 0.1);
          }

          &:hover:nth-child(6) {
            color: red;
          }
        }

        & #lang-contain {
          position: absolute;
          top: 0;

          & span {
            cursor: pointer;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.13);
            display: block;
            border-radius: 100%;
            transition: 0.3s;


            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }

      & .menu-hidden {
        display: none;
      }

      & .menu-visible {
        animation: slideDownMenu forwards 0.2s;
      }
    }


  }

}

.menu-navbar-top-dropdown {
  padding: 10px;
  width: 8vw!important;
  margin-left: -8vw;
  border-radius: var(--border-radius-1);
  .ant-select-item-option-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rc-virtual-list {
    padding: var(--spacing-10);
  }
  .ant-select-item {
    font-size: var(--font-size-m) !important;
    line-height: var(--font-size-m);
    height: 1.5vw;
    display: flex;
  }

  .ant-select-item-option-active {
background-color: #dfeffb;
    border-radius: 0.2vw;
  }
  //left: -8vw!important;
}

.navbar-top-nodata-img{
  height: auto;
}

@media screen and (max-width: 1600px) {
  .clps-open {
  #navbar-top {

      width: calc(100% - 13vw) !important;
    }
  }
}