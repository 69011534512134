@mixin gradient($direction, $color1, $color2) {
  background: linear-gradient($direction, $color1, $color2) !important;
}

@mixin lightShadow {
  box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.03);
}

@mixin glassesEffect($opacity, $blur) {
  background-color: rgba(255, 255, 255, $opacity) !important;
  backdrop-filter: blur($blur) saturate(160%) contrast(45%) brightness(140%);
  -webkit-backdrop-filter: blur($blur) saturate(160%) contrast(45%) brightness(140%);
}

@mixin inputRectangular {
  height: var(--height-input-rectangular);
  width: var(--width-input-rectangular);
  font-family: var(--font-family-poppins-light);
  border-radius: 0.2vw;
  font-size: var(--font-size-m);
}

@mixin actionBarTable {
  @include glassesEffect(0.7, 15px);
  //@include lightShadow;
  box-shadow: 0 0 10px gainsboro;
  display: flex;
  background-color: transparent;
  position: fixed;
  border-radius: 0.8vw;
  font-size: var(--font-size-m);
  left: 40.8%;
  bottom: 3vw;
  padding: 0.6vw 0.7vw;
  overflow: hidden;
  .option_bar_ctn-1 {
    display: flex;
    margin: auto 0;
    background-color: var(--color-bleu);
    color: white;
    padding: 0.7vw;
    border-radius: 0.5vw;
  }
  .section-ipsec-01 {
    margin-left: 1.6vw;
  }
  .section-ipsec-02 {
    display: flex;
    margin-left: 1.7vw;

    .action-edit-table {
      display: flex;
      margin-right: 4.2vw;
      height: 100%;

      div {
        //border: 1px solid red;
        color: var(--color-gris-fonce);
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
        //width: 3vw;
        font-size: var(--font-size-xs);
        text-align: center;
        margin-right: 1vw;
        margin-left: 1vw;
        cursor: pointer;

        img {
          margin-left: auto;
          margin-right: auto;
        }
        svg {
          height: 1vw;

          fill: var(--color-gris-fonce);
        }



        &:hover {
          color: var(--color-bleu);
          svg {
            fill: var(--color-bleu);
          }
        }

        p {
          margin: 0;
        }
      }
    }


  }
  .close-edit-table {
    //display: none;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    width: 3.7vw;
    display: flex;
    border-left: 1px solid var(--color-gris-clair);
    cursor: pointer;

    svg {
      margin: auto;
      color: var(--color-gris-clair);
      font-size: 1vw;
    }
  }
  //display: flex;
  //position: fixed;
  //width: 30.5vw;
  //height: 3.8vw;
  //border-radius: 1.5vh;
  //left: 50%;
  //transform: translateX(-30%);
  //bottom: 1vh;
  //padding: 0.75vw;
  //background-color: white;
  //overflow: hidden;
  //.option_bar_ctn-1 {
  //  width: 30%;
  //  border-radius: 0.5vw;
  //  background-color: var(--color-bleu);
  //  display: flex;
  //  justify-content: space-around;
  //  align-items: center;
  //  font-size: var(--font-size-m);
  //  color: white;
  //
  //  .number-edit-table {
  //    margin-top: auto;
  //    margin-bottom: auto;
  //    width: auto;
  //  }
  //}
  //
  //
  //#section-ipsec-02 {
  //  width: 59%;
  //  display: flex;
  //  justify-content: center;
  //}
  //
  //.action-edit-table {
  //  display: flex !important;
  //  justify-content: space-around;
  //  width: 100%;
  //  height: 100%;
  //}
  //.action-edit-table > div {
  //  display: flex;
  //  flex-direction: column;
  //  justify-content: space-around;
  //  color: #999999;
  //  cursor: pointer;
  //  &:hover {
  //    color: var(--color-bleu);
  //  }
  //  svg {
  //    font-size: 0.8vw;
  //  }
  //  p {
  //    font-size: var(--font-size-m);
  //  }
  //  svg,
  //  p {
  //    margin-left: auto;
  //    margin-right: auto;
  //    margin-bottom: 0;
  //  }
  //}
  //
  //.close-edit-table {
  //  height: 3.8vw;
  //  width: 4vw;
  //  border-left: 1px solid var(--color-gris-clair);
  //  position: absolute;
  //  right: 0;
  //  top: 0;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  svg {
  //    cursor: pointer;
  //    font-size: 1vw;
  //  }
  //}
}

@mixin antdSelectRectangular {
  font-family: var(--font-family-poppins-light);
  .ant-select {
    height: var(--height-input-rectangular);
    width: var(--width-input-rectangular);
    font-size: var(--font-size-m);

    .ant-select-selection-search {
      display: flex;

      .ant-select-selection-search-input {
        margin: auto !important;
      }
    }

    .ant-select {
      height: var(--height-input-rectangular);
      width: var(--width-input-rectangular);
    }

    .ant-select-selection-item {
      align-items: center;
      display: flex;
    }

    .ant-select-selector, input, {
      height: 100%;
      margin: auto !important;
      border-radius: 0.2vw !important;
    }

    .ant-select-selection-placeholder {
      margin: auto 0;
      line-height: var(--font-size-m);
    }

    .ant-select-arrow {
      margin-top: 0;
      top: 50% !important;
      transform: translateY(-50%);
    }
  }
}

@mixin mainPageWithTab {
  font-family: var(--font-family-poppins);
  .title_page {
    display: flex;

    img {
      height: var(--img-title-page-height);
      margin-top: auto;
      margin-bottom: auto;
    }

    h2 {
      margin-bottom: 0;
      margin-left: 0.55vw;
      font-family: var(--font-family-poppins-semibold);
      font-size: var(--font-size-xxl);
    }
  }

  .tabs {
    //margin-top: 1.1vw;
    overflow: visible;

    .ant-tabs-tab {
      margin-right: 2.5vw;
      font-size: var(--font-size-l);
      color: #cecece;
      padding: 0;
      &:first-child {
        margin-left: 0;
      }
      .ant-tabs-tab-btn {
        margin: 0!important;
        padding: 0.2vw 0.6vw!important;
      }
    }

    .ant-tabs-nav {
      margin-top: 1vw;
      padding-left: 1vw;
      margin-bottom: 1.6vw;
      &:before {
        border: none;
      }
      border: none;
    }


    .ant-tabs-ink-bar {
      height: 100%;
      background-color: transparent;

      &:before {
        @include lightShadow;
        content: "";
        position: absolute;
        background-color: #ffffff;
        border-radius: 0.4vw;
        z-index: -1;
        height: 100%;
        width: 100%;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--color-noir);
      font-family: var(--font-family-poppins-medium);
      border: solid 1px #dbdbdb;
      border-radius: 5px;
    }

    .rt-table {
      @include lightShadow;
      background-color: white;
      border-radius: var(--border-radius-1);
      padding-top: 0.5vw;
      padding-bottom: 0.5vw;


    }
  }
}

@mixin mainPageWithTable {
  font-family: var(--font-family-poppins);
  .title_page {
    display: flex;

    img {
      height: var(--img-title-page-height);
      margin-top: auto;
      margin-bottom: auto;
    }

    h2 {
      margin-bottom: 0;
      margin-left: 0.55vw;
      font-weight: bold;
      font-size: var(--font-size-xxl);
    }
  }


  .ant-tabs-tab {
    margin-left: 0.9vw !important;
    margin-right: 1.85vw;
    font-size: var(--font-size-l);
    color: #cecece;
  }


  .ant-tabs-ink-bar {
    height: 100%;
    background-color: transparent;

    &:before {
      content: "";
      position: absolute;
      background-color: white;

      border-radius: 0.4vw;
      z-index: -1;
      height: 100%;
      width: 120%;
      left: -10%;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
    font-weight: 500;

  }

  .rt-table {
    @include lightShadow;
    background-color: white;
    border-radius: var(--border-radius-1);
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
  }

}

@mixin buttonConfirmgradien {
  margin: auto var(--spacing-15)!important;
  border-radius: 5vw!important;
  padding: var(--spacing-10) var(--spacing-20)!important;
  color: white!important;
  background: var(--color-degrade-violet-bleu)!important;
  border: none!important;
}

@mixin buttonCancelWhite() {
  margin: auto var(--spacing-15)!important;
  border-radius: 5vw!important;
  padding: var(--spacing-10) var(--spacing-20)!important;
  color: #7e7e7e!important;
  background-color: white!important;
  border: 1px solid #7e7e7e!important;
}

@mixin tableauAutoHeight {
  .ant-tabs, .ant-tabs-content-holder, .ant-tabs-content, .ant-tabs-tabpane, .rt-table, .ReactTable{
    height: 100%;
  }
}