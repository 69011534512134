label {
  color: #7e7e7e;
  font-size: 10px;
}
.compte{
  background: url("../../assets/images/homeMenu/Fond.jpg") no-repeat;
  margin: 0;
  width: 100vw;
  padding-top: 7vh;
  //background-attachment: fixed;
  background-size:  cover;
  background-position: center;
  height: 100vh;
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  overflow: hidden;

.ctn-2 {
    margin: 0;
    width: 100vw;
    //background-attachment: fixed;
    background-size:  cover;
    background-position: center;
    position: relative;
    display: flex;
    // align-items: center;
    padding-top: 13vh;
    padding-bottom: 2.5vh;
    justify-content: center;
    overflow: hidden;
    

    .profile-menu {
        border-radius: 70px;
        height: 11.3vw;
        overflow: hidden;
        width: 11.3vw;
        // position: relative;
        cursor: pointer;
        // margin-top: auto;
        // margin-bottom: auto;
        img{
          height: 100%;
          width: 100%;
        }
      }

      .ctn-img {
        padding-bottom: 1.5vw;
        // border-bottom: 1px solid #cecece;
        display: flex;
        border-radius: 90px;

        .upload-img {
          // float: left;
          position: absolute;
          // left: 0px;
          bottom: 15vh;
          left: 50vw;
          z-index: 1000;
          background-color: #92AD40;
          padding: 5px;
          color: #FFFFFF;
          font-weight: bold;
        }
  
        img {
          height: 8vw;
          width: 8vw;
          border-radius: 80px;
          object-fit: cover;
          margin-right: 1vw;
        }
      }

      .ctn-img-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: var(--font-size-m);

        p {
          font-size: var(--font-size-m);
          color: #cecece;
          margin-top: 0.5vw;
          margin-bottom: 0;
        }

        .ctn-img-buttons {
          display: flex;

          .inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
            margin-top: auto;
            margin-bottom: auto;
          }

          .inputfile :hover{
            cursor: pointer;
          }

          label {
            margin-top: auto;
            margin-bottom: auto;
            transition: .5s;
          }
          label:hover {
            color: #417bf6;
            cursor: pointer;
          }

          button {
            font-size: var(--font-size-m);
            margin-top: auto;
            margin-bottom: auto;

            color: #cecece;
            border: none;
            outline: none;
            background-color: transparent;
            padding-left: 1.2vw;
            transition: .5s;
          }

          button:hover {
            color: red;
          }

          label {
            font-size: var(--font-size-m);

            color: #cecece;
            padding-right: 1.2vw;
            border-right: 2px solid #cecece;
            //font-size: ;
          }
        }
      }
}
}
.btn-compte {
  background-color: #417bf6;
  color: #FFFFFF;
}
.btn-update {
  background-color: red;
  color: #FFFFFF;
}
.btn-update:hover {
  background-color: red;
  color: #FFFFFF;
}
.btn-compte:hover {
  background-color: #417bf6;
  color: #FFFFFF;
}