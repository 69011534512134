.home-menu{
    background: url("../../assets/images/homeMenu/Fond.jpg") no-repeat;
    margin: 0;
    width: 100vw;
    //background-attachment: fixed;
    background-size:  cover;
    background-position: center;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    #menu-container{
        cursor: pointer;
        ul{
            li{
                cursor: pointer;
                a{
                    cursor: pointer;
                }
            }
        }
        #arm{
            opacity: 0;
            transition: .3s ease opacity;
            position: absolute;
            z-index: 100000;
            bottom: 0;
            img{
                width: 24.5vw;
                // transition: all .3s ease;
            }
            &.left{
                img{
                    transform: scaleX(1);
                }
            }
            // &.bottom-right{
            //     bottom: 0;
            //     transform: scaleX(-1);
            // }
            // &.top-left{
            //     top: 0;
            //     transform: scaleY(-1);
            // }
            &.right{
                img{
                    transform: scaleX(-1);
                }
            }
        }
        .first-raw{
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            // align-items: flex-end;
            li{
                position: relative;
                align-self: flex-end;
                //margin: 3vh 1.2vw;
                margin: 1vw;
                padding: 2vw 3vw;
                border-radius: 20px;
                z-index: 1;
                .link-background{
                    position: absolute;
                    opacity: 0.8;
                    border-radius: 35px;
                    top: 0; left: 0; right: 0; bottom: 0;
                    z-index: -1;
                    &:before{
                        border-radius: 25px;
                        content: "";
                        background: inherit !important;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        box-shadow: inset 0 0 0 3000vw rgba(255, 255, 255, .9);
                        filter: blur(5px);
                    }
                }
                &.wanup{
                    box-shadow: 0 0 25px 1px rgba(148,96,216,.4);
                    transition: all ease .3s;
                    img{
                        width: 12vw;
                        margin-top: 1vw;
                    }
                }
                &.sophia{
                    
                    box-shadow: 0 0 30px 1px rgba(184, 215, 171, .6) ;
                    transition: all ease .3s;
                    text-align: center;
                    img{
                        max-width: 8vw;
                    }
                }
                &.rfiber{
                    box-shadow: 0 0 25px 1px rgba(80, 195, 194, .7);
                    transition: all ease .3s;
                    text-align: center;
                    img{
                        width: 8vw;
                        max-width: 9vw;
                    }
                }
            }
        }
        .second-raw{
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;
            list-style-type: none;
            li{
                position: relative;
                align-self: flex-start;
                //margin: 3vh 1.2vw;
                margin: 1vw;
                padding: 2vw 2.6vw;
                border-radius: 20px;
                .link-background{
                    position: absolute;
                    opacity: 0.8;
                    border-radius: 35px;
                    top: 0; left: 0; right: 0; bottom: 0;
                    z-index: -1;
                    &:before{
                        border-radius: 25px;
                        content: "";
                        background: inherit !important;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        box-shadow: inset 0 0 0 3000vw rgba(255, 255, 255, .9);
                        filter: blur(5px);
                    }
                }
                &.spirit{
                    box-shadow: 0 0 25px 1px rgba(149, 149, 149, .4);
                    transition: all ease .3s;
                    text-align: center;
                    #spirit{
                        display: flex;
                        height: 4vw;
                        img{
                            align-self: center;
                            width: 12vw;
                            margin-bottom: 0.9vw;
                        }
                    }
                }
                &.athena{
                    box-shadow: 0 0 25px 1px rgba(255, 164, 46, .5);
                    transition: all ease .3s;
                    text-align: center;
                    img{
                        width: 12vw;
                    }
                }
            }
        }


        .wanup:hover{
            box-shadow: 0 0 25px 1px rgba(148,96,216, 1);
            transform: scale(1.1);
        }
        .sophia:hover{
            box-shadow: 0 0 25px 1px rgba(184, 215, 171, 1);  
            transform: scale(1.1);
        }
        .rfiber:hover{
            box-shadow: 0 0 25px 1px rgba(80, 195, 194, 1);
            transform: scale(1.1);
        }
        .spirit:hover{
            box-shadow: 0 0 25px 1px rgba(149, 149, 149, 1);
            transform: scale(1.1);
        }
        .athena:hover{
            box-shadow: 0 0 25px 1px rgba(255, 164, 46, 1);
            transform: scale(1.1);
        }
    }
    #menu-container:hover{
        #arm{
            opacity: 1;
        }
    }

}
