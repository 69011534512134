.button-confirm {
    background-color: #417bf6;
    color: white;
    border-color: #417bf6;
}

.button-cancel {
    background-color: red;
    color: white;
    border-color: red;
}
.avatar {
    background-color: red;
}

.portail {
     img {
        width: 17vh;
        height: 5.5vw
     }
}
.accès-wanup {
    display: flex; 
    align-items : baseline;
    justify-content: space-between;
}