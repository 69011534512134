#loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  background: url("./assets/images/intro-wanup.gif") no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

// @media screen and (max-width: 1000px) { 
//     .App {
//         background-image: url('./assets/errorMobile.jpg');
//         background-repeat: no-repeat;
//         background-size: cover;
//         height: 100vh;
//         width: 100vw;
//     } 
// }