.UserForm {
  font-size: var(--font-size-m)!important;

  .ant-select, .ant-input, .ant-select-arrow, label {
    font-size: var(--font-size-m)!important;
  }

  figure {
    position: relative;
    height: 9vw;
    width: 9vw;
    border-radius: 50%;
    overflow: hidden;
    margin: 3vw auto;
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }


  h4 {
    font-size: var(--font-size-l);
    font-weight: bold;
    margin-bottom: 0.6vw;
  }

  & > div {
    margin-bottom: 1.6vw;
  }

  .ctn__wanup__access {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      flex-direction: column;
      width: 48%;
    }

    label {
      font-weight: bolder;
    }
  }

  .user__form__select {
    width: 100%!important;
  }

  .mobile__invalid, .mobile__empty, .email__exist, .email__invalid, .lastname__empty, .firstname__empty, .email__empty {
    display: none;
  }
  .ctn__error {
    color: red;
    margin-top: -0.8vw;
    padding-left: 0.2vw;
    margin-bottom: 1vw;
    display: flex;
    flex-direction: column;
  }

  .border-error {
    border: 1px solid red!important;
  }
}