.rstpwd {
    height: 100vh;
    width: 100%;
    display: flex;
    background: url("../../assets/images/Fond-Login.jpg");
    //background: url("../../../assets/maquettte/Login-Popup-3.jpg");
    background-size: cover;
    background-position: center;
    //position: relative;
    .ctn_reset_form {
      margin-top: 36.7vh !important;
  
      margin: auto;
      font-size: var(--font-size-m);
      background-color: white;
      border-radius: 1vw;
      //padding: 0.9vw;
      text-align: center;
      width: 28vw;
      height: 17vw;
  
      padding-top: var(--spacing-20);
      h1 {
        font-size: var(--font-size-xl);
        font-family: "Poppins SemiBold", sans-serif;
        margin-bottom: 1.6vw;
        //margin-top: 0.9vw;
      }
  
      .ctn_input_text {
        margin-bottom: var(--spacing-15);
        display: flex;
        flex-direction: column;
  
        label {
          font-family: "Poppins Light", sans-serif;
          margin-bottom: 0.6vw;
        }
  
        input {
          outline: none;
          padding-left: 3%;
          width: 14.7vw;
          margin-left: auto;
          margin-right: auto;
          border-radius: 10vw;
          border: 1px solid #e7e7e7;
          height: 1.6vw;
          font-size: var(--font-size-m);
  
          &:focus {
            border: 3px solid #417bf6;
          }
        }
      }
  
      .ctn_btn {
        display: flex;
        justify-content: center;
        padding: var(--spacing-15);
  
        button {
          //height:
          margin: 0 var(--spacing-10) !important;
          border-radius: 10vw !important;
          font-size: var(--font-size-m);
          padding: 0.5vw 1.5vw !important;
          outline: none;
          &:first-child {
            background: linear-gradient(0.25turn, #427ef6, #a242f6) !important;
            color: white !important;
            border: none !important;
          }
          &:last-child {
            color: #7e7e7e !important;
            background-color: white !important;
            border: none !important;
          }
        }
      }
    }
  }