:root {

    --base-height-table-body: 26vw;
    // font
  
    --font-family-poppins: 'Poppins', sans-serif;
    --font-family-poppins-regular: 'Poppins Regular', sans-serif;
    --font-family-poppins-semibold: 'Poppins SemiBold', sans-serif;
    --font-family-poppins-bold: 'Poppins Bold', sans-serif;
    --font-family-poppins-light: 'Poppins Light', sans-serif;
    --font-family-poppins-medium: 'Poppins Medium', sans-serif;
  
    --font-size-xxxl: 1.5625vw;
    --font-size-xxl: 1.27vw;
    --font-size-xl: 1vw;
    --font-size-l: 0.69vw;
    --font-size-m: 0.59vw;
    --font-size-xs: 0.49vw;
  
    // color
  
    --color-gris-clair: #e7e7e7;
    --color-gris-moyen: #c7c7c7;
    --color-gris-fonce: #7e7e7e;
    --color-noir: #161617;
    --color-rouge-clair: #ff304a;
    --color-rouge-fonce: #f21934;
    --color-vert: #0dd2a6;
    --color-orange: #ff8f68;
    --color-bleu: #417bf6;
    --color-bleu-ciel: #f5f8ff;
    --color-degrade-violet-bleu: linear-gradient(0.25turn,#417bf6, #a341f6);
  
    // radius
    --border-radius-1 : 1vw;
  
    // img
    --img-title-page-height: 1.27vw;
  
    //background
    --bg-input-grey: #f3f3f3;
    --bg-blue-active: #397df8;
  
    // input size
    --width-input-text-form: 12.1875vw;
    --height-input-text-form: 1.660vw;
    --height-input-select-rounded: 1.3vw;
  
    --height-input-rectangular: 1.5vw;
    --width-input-rectangular: 11vw;
  
    --spacing-5: 0.25vw;
    --spacing-10: 0.5vw;
    --spacing-15: 1.042vw;
    --spacing-20: 1.5vw;
    --spacing-30: 2.083vw;
    --spacing-45: 3.125vw;
    --spacing-60: 4.167vw;
    --spacing-75: 5.208vw;
    --spacing-90: 6.25vw;
    --spacing-120: 8.333vw;
    --spacing-150: 10.417vw;
    --spacing-180: 12.5vw;
  
    @media (max-width: 1600px) {
      --font-size-xxxl: calc(1.5625vw * 1.2);
      --font-size-xxl: calc(1.27vw * 1.2);
      --font-size-xl: calc(1vw * 1.2);
      --font-size-l: calc(0.69vw * 1.2);
      --font-size-m: calc(0.59vw * 1.2);
      --font-size-xs: calc(0.49vw * 1.2);
  
      --height-input-text-form: calc(1.660vw * 1.2);
      --height-input-select-rounded: calc(1.3vw * 1.2);
  
    }
  }
  