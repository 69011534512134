.btn-add-users {
    color: #417bf6;
    font-size: 4vh;
}

#subClient{
    margin-top: 5vh;
}
#subUser{
    margin-top: 5vh;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: black!important;
    top : 2vh;
    display: none;
}

.btn-add-users:hover {
    cursor : pointer;
}

.utilisateurs {
    img {
        object-fit: cover;
    }
}

.users-list-container {
    margin: 0 auto;
    width: 35vw; height: 45vh;
    overflow-y: scroll
}

.list-subClient {
    display : flex;
    align-items: baseline;
    justify-content: flex-end
}

.Avatar {
    border-radius: 50px;
    background-color: red;
}

.headerPanel {
    display : flex;
    align-items: baseline;
    justify-content: space-between
}
.action-utilisateurs {
    border-radius: 50%;
    height: 7vw;
    overflow: hidden;
    width: 7vw;
    // position: relative;
    cursor: pointer;
    // margin-top: auto;
    // margin-bottom: auto;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
}

.profile-menu {
    border-radius: 50%;
    height: 9.3vw;
    overflow: hidden;
    width: 9.3vw;
    // position: relative;
    cursor: pointer;

    // margin-top: auto;
    // margin-bottom: auto;
    img{
      height: 100%;
      width: 100%;
    }
}

.users-list {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    align-content: space-around;
    border-bottom: #e7e7e7 1px solid
}

.select-roles{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.button-confirm{
    background-color: #417bf6;
    color: white;
    border-color: #417bf6;
}
.button-cancel{
    background-color: red;
    color: white;
    border-color: red;
}


.utilisateurs {
    border-radius: 50%;
    height: 5vw;
    overflow: hidden;
    width: 5vw;
    // position: relative;
    cursor: pointer;
    // margin-top: auto;
    // margin-bottom: auto;
    img{
      height: 100%;
      width: 100%;
    }
}
.sous-utilisateurs {
    border-radius: 50%;
    height: 3vw;
    overflow: hidden;
    width: 3vw;
    position: absolute;
    // position: relative;
    cursor: pointer;
    // margin-top: auto;
    // margin-bottom: auto;
    img{
      height: 100%;
      width: 100%;
    }
}

.outils-container{
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 0.5px #ebedee;
    border-radius: 5px;
    height: 6.5vh;
    margin: 2px;
}

.txt-add-users {
    display: flex;
    justify-content: center;
}

.txt-add-users p {
    margin-top: 1vh;
    color: #417bf6;
}