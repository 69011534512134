@import "../../sass/mixin";
@import "../../sass/variable";

#byos-layout {
  background: #fcfcfc;
  height: 100vh;
  flex-flow: unset !important;
  #content {
    //background: url("../../assets/maquettte/content-LABO-INTERCO.png");
    background-size: cover;
    $heightNavBarTop: calc(var(--spacing-45) + var(--spacing-45) + var(--height-input-text-form));
    padding-top: $heightNavBarTop;
    padding-right: 3vw;
    padding-left: 3vw;
    padding-bottom: 3vw;
    height: 100vh !important;
    overflow: auto;
    transition: 0.3s;

    &.clps-open {
      width: 88vw;
    }

    &.clps-close {
      width: calc(100vw - 5.6vw);
    }
  }
}


.alert_notifications {

  font-size: var(--font-size-m) !important;
  width: 15vw;
  padding: 0;
  //top: 10vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: var(--border-radius-1) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);
  -webkit-backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);
  height: auto;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;

  .alert_notifications_title {
    font-family: "Poppins Light", sans-serif;
    color: #7e7e7e;
    margin-bottom: 0.7vw;
    font-size: var(--font-size-m) !important;
    display: flex;

    .icon_notification {
      height: 0.9vw;
      width: 0.9vw;

    }

    .critical {
      background: url("../../assets/images/Critique.svg") no-repeat;
      background-size: 100% 100%;
      background-position: center center;
    }

    .information {
      background: url("../../assets/images/Infos.svg") no-repeat;
      background-size: 100% 100%;
      background-position: center center;
    }

    .warning {
      background: url("../../assets/images/Degrade.svg") no-repeat;
      background-size: 100% 100%;
      background-position: center center;
    }

    span {
      margin: auto var(--spacing-10);
    }
  }

  .ant-notification-notice-content {
    padding: 0.7vw;
  }

  .ant-notification-notice-message {
    font-size: var(--font-size-m);
    line-height: var(--font-size-l);
    margin-bottom: var(--spacing-5);
  }

  .ant-notification-notice-description {
    font-size: var(--font-size-m);
  }

  .describe_notifications_alert {

    span {

      font-size: var(--font-size-m) !important;

      &:first-child {
        font-family: "Poppins Bold", sans-serif;
      }

      &:last-child {
        font-family: "Poppins Regular", sans-serif;
      }
    }
  }

  .ant-notification-notice-close {

    font-size: var(--font-size-m) !important;
    position: relative;
    width: 15vw;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    margin-right: auto;
    margin-left: auto;
    top: 0;
    right: 0;
    padding: 0.4vw;
    font-family: "Poppins Regular", sans-serif;
    color: #7e7e7e;

  }
}

.fullscreen-bg {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.fullscreen-bg video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

@keyframes searchInput {
  from {
    border: 1px solid rgba($color: #000000, $alpha: 0);
  }
  to {
    border: 1px solid rgba($color: #000000, $alpha: 0.4);
  }
}

@keyframes submenu-dashboard {
  from {
    height: 0;
  }
  to {
    height: 38px;
  }
}

@keyframes submenu-reporting {
  from {
    height: 0;
  }
  to {
    height: 76px;
  }
}

@media screen and (max-width: 1600px) {
  #content {
    &.clps-open {
      width: 87vw !important;
    }
  }
}